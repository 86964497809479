/* eslint-disable no-console */

const initialState = {
	cookiesAccepted: false,
	nonNecessaryCookiesEnabled: false
}

export default {
	state: initialState,
	reducers: {
		SET_COOKIES_ACCEPTED(state){
			return {
				...state,
				cookiesAccepted: true,
			}
		},
		SET_NON_NECESSARY_COOKIES(state, payload) {
			return {
				...state,
				nonNecessaryCookiesEnabled: payload.nonNecessaryCookiesEnabled
			}
		}
	},

	effects: dispatch => ({
		async ACCEPT_COOKIES(_, state){
			const { loginError } = state.user

			if(!!loginError) {
				await dispatch({
					type: 'user/SET_LOGIN_ERROR',
					payload: false
				})
			}

			dispatch({
				type: 'cookie/SET_COOKIES_ACCEPTED'
			})
		}
	})
}
